<template>
    <div v-if="isAdmin && !loading">
        <router-link :to="{ name: 'admin' }">
            <DsFilledButton gray leading-icon="arrow-left">
                Back to admin dash
            </DsFilledButton>
        </router-link>
        <h3>Votes for {{ hackathon.name }}</h3>

        <div v-for="(award, i) in hackathon.awards" :key="i">
            <div class="title-row">
                <h2>{{ award.name }} ({{ totalVotesByAward(i) }} {{ filterVotes ? 'filtered' : 'total' }} votes)</h2>

                <div class="button-row">
                    <DsFilledButton class="outline" @click="filterVotes = !filterVotes">
                        Show {{ filterVotes ? 'all' : 'filtered' }} votes
                    </DsFilledButton>
                    <DsOutlineButton @click="$router.push({ name: 'votes-chart', params: { id: hackathon.id } })">
                        View chart
                    </DsOutlineButton>
                </div>
            </div>

            <div class="projects-list">
                <div v-for="project in projectsByAward(i)" :key="project.id" class="project">
                    <div class="project-title">
                        <h4>{{ project.name }}</h4>
                        <strong>{{ project[voteKey] }} votes</strong>
                    </div>

                    <div
                        v-for="user in project.userList"
                        :key="user.id"
                        class="user"
                    >
                        <span>{{ user.name }}</span>
                        <small v-if="user.isInProject"><em> (In Project)</em></small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    data() {
        return {
            loading: true,
            keyMap: {},
            filterVotes: false,
        };
    },

    created() {
        this.load();
    },

    computed: {
        hackathon() {
            return this.getHackathon(this.$route.params.id);
        },

        ...mapState({
            isAdmin: ({ auth }) => auth.user.isAdmin,
            votes: ({ votes }) => votes.votes,
        }),

        ...mapGetters({
            getProject: 'projects/getProject',
            getUser: 'users/getUser',
            getHackathon: 'hackathons/getHackathon',
            getVotesByHackathonAward: 'votes/getVotesByHackathonAward',
        }),

        voteKey() {
            return this.filterVotes ? 'filteredVotes' : 'totalVotes';
        },
    },

    methods: {
        projectsByAward(awardIndex) {
            const votes = this.getVotesByHackathonAward(this.hackathon.id, awardIndex);
            const projects = {};

            votes.forEach(({ projectId, userId }) => {
                if (projects[projectId]) {
                    projects[projectId].push(userId);
                } else {
                    projects[projectId] = [userId];
                }
            });

            const p = Object.keys(projects).map((key) => {
                const project = this.getProject(key);

                const voters = projects[key]
                    .map((userId) => ({
                        ...this.getUser(userId),
                        isInProject: project?.members?.includes(userId) || project?.teamLead === userId,
                    }))
                    .sort((a, b) => {
                        if (a.isInProject > b.isInProject) return -1;
                        if (a.isInProject < b.isInProject) return 1;

                        return 0;
                    });

                return {
                    id: key,
                    totalVotes: voters.length,
                    filteredVotes: voters.filter(({ isInProject }) => !isInProject).length,
                    userList: voters.filter(({ isInProject }) => {
                        return this.filterVotes ? !isInProject : true;
                    }),
                    name: project.name,
                };
            });

            return p.sort((a, b) => {
                if (a[this.voteKey] > b[this.voteKey]) return -1;
                if (a[this.voteKey] < b[this.voteKey]) return 1;

                return 0;
            });
        },

        totalVotesByAward(awardIndex) {
            const projects = this.projectsByAward(awardIndex);

            return projects && projects.length
                ? projects.reduce((acc, obj) => acc + obj[this.voteKey], 0)
                : 0;
        },

        load() {
            this.loading = true;
            this.$store.dispatch('votes/LOAD_VOTES');
            this.loading = false;
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .title-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: $spacing-200 0;
    }

    .projects-list {
      display: grid;
      grid-gap: $spacing-200;
      grid-template-columns: repeat(1, 1fr);

      @media ($medium) {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    .project {
      @include card;
      margin: 0;
      line-height: 1.5;

      .project-title {
        margin-bottom: $spacing-100;
        display: flex;
        justify-content: space-between;

        strong {
          white-space: nowrap;
          margin-left: $spacing-200;
        }
      }
    }

    .user {
      small {
        color: $color-gray;
      }
    }
</style>
