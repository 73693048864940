<template>
    <div>
        <DsInputField
            v-model="searchText"
            placeholder="Filter users"
            class="search-box"
        />

        <div v-if="noResults" class="no-results">
            <DsInlineAlert>
                No results found

                <template #footer>
                    <DsFilledButton
                        leading-icon
                        type="subtle"
                        gray
                        @click="searchText = ''"
                    >
                        Clear filter
                    </DsFilledButton>
                </template>
            </DsInlineAlert>
        </div>

        <DsListItem
            v-for="user in filteredUsers"
            v-else
            :key="user.uid"
            clickable
            border-style="full"
            @click="$router.push({ name: 'user', params: { id: user.uid } })"
        >
            <template #leading>
                <UserAvatar
                    :size="40"
                    :user="user"
                />
            </template>

            <template #title>
                <p>
                    <DsChip v-if="guestsList.includes(user.email)" class="outline">
                        Guest
                    </DsChip>

                    {{ user.name }}
                </p>

                <small>Joined {{ formatDate(user.creationTime) }}</small>
            </template>

            <template #trailing>
                <DsIcon name="chevron-right" />
            </template>
        </DsListItem>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import UserAvatar from '@/components/UserAvatar';

export default {
    components: {
        UserAvatar,
    },
    data() {
        return {
            searchText: '',
        };
    },

    mounted() {
        this.loadUsers();
    },

    computed: {
        noResults() {
            return this.searchText?.trim()?.length > 0 && this.filteredUsers?.length === 0;
        },

        filteredUsers() {
            return this.searchText
                ? this.users.filter((user) => user.name.toLowerCase().trim().includes(this.searchText.toLowerCase().trim()))
                : this.users;
        },

        ...mapState({
            users: ({ users }) => users.users,
        }),

        ...mapGetters({
            guestsList: 'users/guestsList',
        }),
    },

    methods: {
        formatDate(date) {
            return moment(date).fromNow();
        },

        loadUsers() {
            this.$store.dispatch('users/LOAD_USERS');
            this.$store.dispatch('users/LOAD_GUEST_LIST');
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .search-box {
        margin: $spacing-200;
        max-width: 300px;
    }

    .no-results {
        display: flex;
        align-items: center;
        margin: 0 $spacing-200 $spacing-200;
    }
</style>
